export default function CountUpTimer() {

    // Set the date we're counting down to
    var countDownDate = new Date("-009000-02-01T05:00:00.000Z").getTime();
    // Update the count down every 1 second
    var x = setInterval(function () {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);


        try {
            // Output the result in an element with id="demo"
            document.getElementById("demo").innerHTML = days.toString() + "d " + hours.toString() + "h "
                + minutes.toString() + "m " + seconds.toString() + "s ";
        } catch (e) {
            console.log("failed with displaying numbers")
        }


    }, 1000);
    return (
        <div>
            <header>

                <b id="demo" style ={{fontSize:"24px"}}></b>


            </header>
        </div>
    );
}