import postCard from './pics/glaPostcard.jpg'
import './App.css';
import CountUpTimer from './components/CountUpTimer';
import { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image'



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function App() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function onMobile(windowDimensions) {
    if (windowDimensions.width > 500) {
      return false;
    } else return true;
  }

  console.log(windowDimensions);
  return (
    <div className="App-header">
      {/* <header className="App-header">

      </header> */}
      <div style={{ padding: "0px" }} >
        {onMobile(windowDimensions) ? (
          //  <img src={postCard} className="App-logo" alt="logo" />
          <Image src={postCard} width={windowDimensions.width - 20} />
        ) : (

          <Image src={postCard} fluid width={windowDimensions.width / 2} />
        )}
      </div>

      <p style={{ padding: "10px" }}>
        What if you could vacation where the air was untouched by industrial pollution and the nuclear fallout? Where the water was pure and free from synthetic chemical compounds and biological weapons? Where there is still an ozone layer, and you can frolic in the sun? The location?
      </p>
      <b>Not where, but when.</b>
      <p>
      </p>
      <CountUpTimer></CountUpTimer>
    </div>
  );
}

export default App;
